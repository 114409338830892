


































import {Component, Vue} from 'vue-property-decorator';
import StatsPortalLayout from '@/components/layout/StatsPortalLayout.vue';
import DataTable from '@/components/stats/DataTable.vue';
import Common from '@/utils/common.util';
import {StatsTeamTO} from '@/model/stats/stats.model';
import StatsChart from '@/components/stats/StatsChart.vue';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';

@Component({
  components: {StatsLeagueSelection, StatsPortalLayout, DataTable, StatsChart},
  metaInfo: {
    title: 'Statistiken',
  },
})
export default class StatsTestPage extends Vue {

  statsTeam = [
    new StatsTeamTO('Heim-Zuschauer', 'h', 'attendance', ['attendanceMin', 'attendanceAvg', 'attendanceMax']),
    new StatsTeamTO('Zuschauer (Auswärts)', 'a', 'attendance', ['attendanceAvg'])
  ];

  get both() {
    return Common.PARAM_BOTH;
  }

}
