/**
 * Styles which can not be put into SCSS files, i.e. logo-height.
 */
export default {
	defaultChartHeight: 300, // applies only if in a row without col-breakpoints
	defaultChartWidth: 400, // applies only when in a row without col-breakpoints
	tableLogoHeight: 22, // adapted to line-height
	primaryColor: '#ff6b00', // keep in sync with hf-variables.scss
	secondaryColor: '#D8D8D8', // keep in sync with hf-variables.scss
};

