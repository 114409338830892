






















import BarChart from './BarChart.vue';
import Styles from '@/assets/styles/style';
import {Prop, Vue, Component} from 'vue-property-decorator';
import {StatsParams} from '@/model/stats/stats.model';
import {STATS_SERVICE} from '@/services/stats.service';

@Component({
  components: {BarChart},
})
export default class StatsChart extends Vue {

  @Prop({required: true}) chartType!: string;
  @Prop({required: true}) resource!: string;
  @Prop({required: true}) query!: string;
  @Prop({required: true}) queryParams!: StatsParams;
  @Prop({required: true}) fields!: string[];

  @Prop() title?: string;
  @Prop() showLoading?: boolean;
  @Prop({default: Styles.defaultChartWidth}) width?: number;
  @Prop({default: Styles.defaultChartHeight}) height?: number;
  @Prop({default: () => []}) sync!: [];

  data: any[] = [];

  get rawdata(): any[] {
    return this.data;
  }

  created() {
    STATS_SERVICE.stats(this.params).then((response: any) => {
      this.data = response.data;
    });
  }

  get params(): StatsParams {
    return {
      resource: this.resource,
      query: this.query,
      season: this.queryParams.season,
      league: this.queryParams.league,
      gameType: this.queryParams.gameType,
      team: this.queryParams.team,
      orderBy: this.queryParams.orderBy,
      ha: this.queryParams.ha,
      playerId: this.queryParams.playerId,
      limitRows: this.queryParams.limitRows,
    };
  }

  syncBy() {
    return this.sync;
    // ...mapGetters([selectedSeasonId, selectedLeagueId, selectedGameTypeId, selectedTeamId]),
    // return [this.selectedTeamId, this.selectedSeasonId, this.selectedLeagueId, this.selectedGameTypeId];
  }
}
