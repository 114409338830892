



















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PanelBox extends Vue {
  @Prop(String) title?: string;
  @Prop({default: false}) gapless?: boolean;
  @Prop({default: false}) noMarginTop?: boolean;
  @Prop({default: false, type: Boolean}) emptySlot?: boolean;
}
