<script lang="js">
  // these {} are important
  import {Bar} from 'vue-chartjs-typescript';

  import Stats from '../../utils/stats.util';
  import Styles from '../../assets/styles/style';

  export default {
    name: 'BarChart',
    extends: Bar,
    props: {
      fields: {
        type: Array,
        required: true,
      },
      rawdata: {
        type: Array,
        required: true,
      },
    },

    watch: {
      datasets: function () {
        if (this._chart) {
          this._chart.destroy();
        }
        this.renderMyChart();
      }
    },
    computed: {
      datasets: function () {
        return this.fields.map(field => {
          return {
            label: this.$t(`label.${field}`),
            data: this.extractData(this.rawdata, field),
            backgroundColor: Styles.primaryColor,
          };
        });
      },
    },
    methods: {
      extractData: function (data, field) {
        return data.map(d => Stats.round(d[field], 0));
      },
      extractLabels: function (data) {
        return data.map(d => d.teamId);
      },
      renderMyChart: function () {
        this.renderChart(
          {
            labels: this.extractLabels(this.rawdata),
            datasets: this.datasets,
          },
          {
            legend: {
              display: this.fields.length > 1,
            },
            scales: {
              xAxes: [{
                barPercentage: 0.9,
                categoryPercentage: 0.9,
              }],
              yAxes: [{
                gridLines: {
                  display: true,
                },
              }],
            },
          },
        );
      }
    },
    mounted() {
      this.renderMyChart();
    },
  };
</script>
